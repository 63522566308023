import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { LanguageFilterComponent } from './shared/language-filter/language-filter.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'home', // TODO: reconsider
    component: HomeComponent,
  },
  {
    path: ':languageTag',
    component: LanguageFilterComponent,
    children: [
      {
        path: 'courses',
        loadChildren: './courses/courses.module#CoursesModule'
      },
      {
        path: 'user',
        loadChildren: './user/user.module#UserModule'
      }
    ]
  },
  {
    path: '**',
    component: NotFoundComponent,
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'corrected',
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
