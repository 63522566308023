import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as firebase from 'firebase';

@Injectable()
export class SnackService {

  constructor(private snackbar: MatSnackBar) { }

  notify(message: string) {
    this.snackbar.open(message);
  }
  success(message: string) {
    this.snackbar.open(message, null, {panelClass: 'success'});
  }
  error(message: string) {
    this.snackbar.open(message, null, {panelClass: 'error'});
  }
  firebaseError(error: firebase.FirebaseError) {
    console.error(error);
    this.snackbar.open(error.message, null, {panelClass: 'error'});
  }
}
