import { Router, NavigationEnd } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DetectLanguageService } from './shared/services/detect-language.service';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private detectLanguageService: DetectLanguageService,
    private translateService: TranslateService,
    private router: Router,
  ) { }

  ngOnInit() {
    const defaultLanguage = this.detectLanguageService.determineLanguage();
    this.translateService.use(defaultLanguage);
    this.router.events
      .pipe(filter(event =>
        event instanceof NavigationEnd))
      .subscribe(event =>
        console.info(`AppComponent router event`, event)
      );
  }
}
