import { Component, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LanguageTags } from '../models/language-tags.model';
import { map } from 'rxjs/operators';
import { DetectLanguageService } from '../services/detect-language.service';

@Component({
  selector: 'ld-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
})
export class LanguageSwitchComponent implements OnInit {


  otherLanguage$: Observable<LanguageTags>;

  constructor(
    private translateservice: TranslateService,
    private detectLanguageService: DetectLanguageService,
  ) { }

  ngOnInit(): void {
    this.otherLanguage$ = this.translateservice.onLangChange
      .pipe(map(langChangeEvent =>
        this.otherLanguage(
          this.detectLanguageService.mapStringToTag(langChangeEvent.lang)
        )
      ));
  }

  private otherLanguage(language: LanguageTags): LanguageTags {
    return language === LanguageTags.Polish ? LanguageTags.Russian : LanguageTags.Polish;
  }


}
