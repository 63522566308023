import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './not-found/not-found.component';
import { LanguageFilterComponent } from './language-filter/language-filter.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageSwitchComponent } from './language-switch/language-switch.component';
import { FlagComponent } from './flag/flag.component';
import { DetectLanguageService } from './services/detect-language.service';
import { AuthService } from './services/auth.service';
import { FloatingMenuComponent } from './floating-menu/floating-menu.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { SvgIconsComponent } from './svg-icons/svg-icons.component';
import { SnackService } from './services/snack.service';
import { MatSnackBarModule, MatSnackBar } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';

const exportedComponents: Type<any>[] = [
  NotFoundComponent,
  LanguageFilterComponent,
  LanguageSwitchComponent,
  FlagComponent,
  FloatingMenuComponent,
  UserMenuComponent,
  SvgIconsComponent
];

const declarations: Type<any>[] = [
  ...exportedComponents,
];


@NgModule({
  declarations: [...declarations],
  exports: [
    ...exportedComponents,
    TranslateModule,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,
    MatCardModule,
  ],
  providers: [
    AuthService,
    DetectLanguageService,
    MatSnackBar,
    SnackService,
  ],
})
export class SharedModule { }
