import { announcementTranslations } from './../models/announcements.translations';
// Template based on https://material.angular.io/components/card/examples
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Announcement } from '../models/announcement.model';
import * as _ from 'lodash';

interface Student {
  icon: string;
  color: string;
}
@Component({
  selector: 'ld-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.scss']
})
export class AnnouncementComponent implements OnInit, OnChanges {
  @Input() announcement: Announcement;

  keys = announcementTranslations;
  students: Student[] = [];
  places: boolean[] = [];

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    const placesTotal = _.get(changes, 'announcement.currentValue.placesTotal');
    if (!placesTotal) {
      return;
    }

    const { placesLeft } = this.announcement;
    this.students = [];
    for (let s = 0; s < placesTotal; s++) {
      this.places.push(s >= placesTotal - placesLeft);
      this.students.push(
        s < placesTotal - placesLeft
          ? { icon: 'person', color: 'primary' }
          : { icon: 'person_outline', color: 'accent' }
      );
    }
  }

  ngOnInit(): void {
  }

  hasDay(day: number): boolean {
    return this.announcement.days.includes(day);
  }

}
