import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { switchMap, tap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth, User } from 'firebase/app';
import { Observable, from, of } from 'rxjs';
import { AppUser } from '../models/app-user.model';

interface UserCredentials {
  email: string;
  password: string;
}

@Injectable()
export class AuthService {
  user$: Observable<AppUser>;
  constructor(
    private afAuth: AngularFireAuth,
    private angularFirestore: AngularFirestore,
  ) {
    this.user$ = this.afAuth.authState
      .pipe(
        switchMap(user =>
          user
            ? this.angularFirestore.doc<AppUser>(`users/${user.uid}`).valueChanges()
              .pipe(
                tap(change => console.log('user change', change)),
                // map(userChange => userChange.user)
                )
            : of(null)
        )
      );
  }

  registerUser(credentials: UserCredentials): Observable<auth.UserCredential> {
    return from(this.afAuth.createUserWithEmailAndPassword(credentials.email, credentials.password));
  }

  signUserIn(credentials: UserCredentials): Observable<auth.UserCredential> {
    return from(this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password));
  }

  googleSignIn(): Observable<auth.UserCredential> {
    const provider = new auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    return from(this.afAuth.signInWithPopup(provider))
      .pipe(tap(credentials =>
        this.updateUserData(credentials.user)));
  }

  facebookSignIn(): Observable<auth.UserCredential> {
    const provider = new auth.FacebookAuthProvider();
    return from(this.afAuth.signInWithPopup(provider))
      .pipe(tap(credentials =>
        this.updateUserData(credentials.user)));

  }

  signUserOut() {
    return from(this.afAuth.signOut());
  }

  private updateUserData(user): Observable<any> {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<AppUser> = this.angularFirestore.doc(`users/${user.uid}`);

    const userData: AppUser = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL
    };

    return from(userRef.set(userData, { merge: true }));
  }

}
