import { Component, OnInit } from '@angular/core';
import { DetectLanguageService } from '../shared/services/detect-language.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ld-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [DetectLanguageService],
})
export class HomeComponent implements OnInit {

  language = 'ru';
  constructor(
    private detectLanguageService: DetectLanguageService,
    private router: Router,
  ) { }

  ngOnInit() {
    const languageTag = this.detectLanguageService.determineLanguage();
    this.router.navigate([languageTag, 'courses']);
  }

}
