import { ActivatedRoute } from '@angular/router';
import { AnnouncementsService } from './announcements.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Announcement } from '../models/announcement.model';
import { LanguageTags } from 'src/app/shared/models/language-tags.model';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { DetectLanguageService } from 'src/app/shared/services/detect-language.service';

@AutoUnsubscribe()
@Component({
  selector: 'ld-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
  providers: [AnnouncementsService],
})
export class AnnouncementsComponent implements OnInit, OnDestroy {
  @Input() courseSlug?: string;
  @Input() columns = 1;
  
  announcements$: Observable<Announcement[]>;

  private subscription: Subscription;

  constructor(
    private announcementsService: AnnouncementsService,
    private translateService: TranslateService,
    private detectLanguageService: DetectLanguageService,
  ) { }

  ngOnInit(): void {
    this.watchAnnouncements(
      this.detectLanguageService.mapStringToTag(
        this.translateService.currentLang));
    this.subscription = this.translateService.onLangChange
      .subscribe(langChangeEvent =>
        this.watchAnnouncements(
          this.detectLanguageService.mapStringToTag(langChangeEvent.lang)));
  }

  ngOnDestroy() { }

  private watchAnnouncements(lang: LanguageTags) {
    this.announcements$ = this.announcementsService.getAnnouncements(lang, this.courseSlug);
  }

}
