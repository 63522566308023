import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DetectLanguageService } from '../services/detect-language.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ld-language-filter',
  templateUrl: './language-filter.component.html',
  styleUrls: ['./language-filter.component.scss'],
})
export class LanguageFilterComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private detectLanguageService: DetectLanguageService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const languageTag = _.get(params, 'languageTag');
      if (languageTag) {
        this.translateService.use(languageTag);
        this.detectLanguageService.rememberLanguage(languageTag);
      }
    });
  }

}
