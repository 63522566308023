import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LanguageTags } from '../models/language-tags.model';
import { map, tap } from 'rxjs/operators';
import { DetectLanguageService } from '../services/detect-language.service';

@Component({
  selector: 'ld-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss']
})
export class UserMenuComponent implements OnInit {

  lang$: Observable<LanguageTags>;

  constructor(
    public authService: AuthService,
    private router: Router,
    private translateService: TranslateService,
    private deterctLanguageService: DetectLanguageService,
  ) { }

  ngOnInit(): void {
    this.lang$ = this.translateService.onLangChange
      .pipe(map(langChange =>
        this.deterctLanguageService.mapStringToTag(langChange.lang)
      ));

      this.authService.user$.pipe(
        tap(user =>
          console.log(user)));
  }

  signOut() {
    this.authService.signUserOut().subscribe(
      () => this.router.navigateByUrl('/'),
      error => console.error(error) // TODO elaborate
    );
  }

}
