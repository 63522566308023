import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LanguageTags } from '../../shared/models/language-tags.model';
import { Announcement } from '../models/announcement.model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementsService {

  constructor(private firestore: AngularFirestore) { }

  getAnnouncements(languageTag = LanguageTags.Russian, courseSlug = ''): Observable<Announcement[]> {
    return this.firestore
      .collection('announcements', ref => ref.where('language', '==', languageTag))
      .snapshotChanges()
      .pipe(
        map(collectionItems =>
          collectionItems
            .map(item =>
              item.payload.doc.data() as Announcement)
            .filter(announcement =>
              (courseSlug === '' || _.get(announcement, 'courseSlug', '') === courseSlug))
        )
      );
  }
}
