import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatCommonModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AnnouncementComponent } from './announcement/announcement.component';
import { AnnouncementsComponent } from './announcements/announcements.component';



@NgModule({
  declarations: [AnnouncementComponent, AnnouncementsComponent],
  exports: [AnnouncementComponent, AnnouncementsComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatCommonModule,
    MatCardModule,
    MatIconModule,
  ]
})
export class AnnouncementsModule { }
