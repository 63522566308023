import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { LocalStorageService } from 'ngx-webstorage';
import { LanguageTags } from '../models/language-tags.model';

@Injectable()
export class DetectLanguageService {

  static readonly languageKey = 'languageChosen';

  constructor(private localStorageService: LocalStorageService) { }

  determineLanguage(): LanguageTags {
    return this.localStorageService.retrieve(DetectLanguageService.languageKey) ||
      this.detectLanguage();
  }

  rememberLanguage(laguageTag: LanguageTags) {
    this.localStorageService.store(DetectLanguageService.languageKey, laguageTag);
  }

  mapStringToTag(lang: string): LanguageTags {
    return lang.toLowerCase() === 'pl'
      ? LanguageTags.Polish
      : LanguageTags.Russian;
  }

  private detectLanguage(): LanguageTags {
    if (window?.navigator?.languages &&
      window?.navigator?.languages.some(lang =>
        lang.substring(0, 2).toLowerCase() === 'pl')) {
      return LanguageTags.Polish;
    }

    const navLang = window?.navigator?.language || window?.navigator['userLanguage'] || '';
    if (navLang.substring(0, 2).toLowerCase() === 'pl') {
      return LanguageTags.Polish;
    }

    return LanguageTags.Russian;
  }

}
