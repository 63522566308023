import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { svgIcons } from './svg-icons.model';

@Component({
  selector: 'ld-svg-icons',
  templateUrl: './svg-icons.component.html',
  styleUrls: ['./svg-icons.component.scss']
})
export class SvgIconsComponent implements OnInit {

  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
  ) { }

  ngOnInit(): void {
    svgIcons.forEach(tag =>
      this.matIconRegistry.addSvgIcon(
        `ld_${tag}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`/assets/images/icons/${tag}.svg`)
      )
    );
  }
}
